<template id="modalQualtrics">
    <div>
        <v-btn v-bind:id="idQuizButton" :aria-label="item.data.cardAction.title.trim()"
            v-bind:data-title="item.data.cardAction.value.trim()"
            class="btn btn-chat-bot btn-chat-bot-act options-register-user qualtrics" v-bind:class="[claseGeneral]"
            :data-ref="refId" @click.stop="dialog = true">
            {{ item.data.cardAction.title.trim() }}
        </v-btn>
        <v-dialog v-model="dialog" max-width="400" persistent >
            <v-card>
                <v-card-title style="height: 40px !important;background-color: rgb(238, 19, 44) !important;">
                </v-card-title>
                <v-card-text style="padding-right: 0%;padding-left: 6%;">
                    <v-btn @click="dialog = false"
                        style="left: 78%;position: absolute;top: .3%;border: 1px solid white;color: white;background-color: rgb(238, 19, 44) !important;z-index: 2;"><v-icon>mdi-close</v-icon></v-btn>
                    <iframe :src="url" width="350" height="800" title="Encuesta"
                        style="width: 100%;transform: scale(0.90);transform-origin: 0px 0px;top: 0;left: 0;height: 100vh;border: none;padding-bottom: 100px;"></iframe>

                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Vue from "vue"
import QualtrixData from "@/models/qualtrixData";
import QualtrixDataPR from "@/models/qualtrixDataPR";

export default Vue.component("EncuestaQualtrix", {
    props: [
        "item",
        "claseGeneral",
        "refId"
    ],
    data() {
        return {
            modalQuatrix: false,
            idQuizButton: "btnShowQuiz",
            visibleSurvey: false,
            url: this.createUrl(),
            dialog: false
        }
    },
    methods: {
        createUrl() {
            let pais = String(localStorage.getItem('pais'));
            let isBase64 = String(localStorage.getItem('isSurveyBase64')) == 'true';
            let url = '';

            if (isBase64) {
                if (pais == 'PR')
                    url = this.getUrlQualtricsPR64();
                else
                    url = this.getUrlQualtrics64();
            }
            else {
                if (pais == 'PR')
                    url = this.getUrlQualtricsPR();
                else
                    url = this.getUrlQualtrics();
            }

            return url;
        },
        obtainQualtrixData() {
            let dataQ = new QualtrixData();

            dataQ.Usuario = this.item.email;
            dataQ.Canal = 'Web';
            dataQ.Servicio = 'ClaroBot';
            dataQ.Fecha = new Date().toLocaleDateString().replaceAll("/","-");
            dataQ.Hora_inicio = this.item.dateInit;
            dataQ.Hora_fin = this.formatAMPM(new Date());
            dataQ.Motivo_visita1 = this.item.motivoVisita;
            dataQ.Motivo_visita2 = String(localStorage.getItem('buttonNameForSurvey'));
            dataQ.ID_Conversacion = this.item.guidKeyChat;
            dataQ.Tipo_Cliente = this.item.clientType;

            return dataQ;
        },
        obtainQualtrixDataPR() {
            let dataQ = new QualtrixDataPR();
            let dateInit = new Date();

            dataQ.region = this.item.region;
            dataQ.nombre_usuario = this.item.name;
            dataQ.canal_ingreso = 'WEB';
            dataQ.nombre_servicio = 'ClaroBot';
            dataQ.fecha_atencion = dateInit.toLocaleDateString().replaceAll("/","-");
            dataQ.hora_atencion = this.item.dateInit;
            dataQ.telefono = this.item.lineNumber;
            dataQ.motivo_visita = this.item.motivoVisita;
            dataQ.id_conversacion = this.item.guidKeyChat;
            dataQ.tipo_cliente = this.item.clientType;
            dataQ.tiempo_total_atencion = this.differenceTime(dateInit, dataQ.hora_atencion); 
            dataQ.email = this.item.email;

            return dataQ;
        },        
        formatAMPM(date) {
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            let strTime = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + ampm;
            return strTime;
        },
        differenceTime(dateInit, timeInit) {
            let hour24 = 0;
            let hour = timeInit.split(' ')[0].split(':')[0];
            let minutes24 = Number(timeInit.split(' ')[0].split(':')[1]);
            let ampm = timeInit.split(' ')[1];
            let dateNow = new Date();

            if (ampm == 'AM')
                hour24 = hour == '12' ? 0 : Number(hour);
            else
                hour24 = hour == '12' ? Number(hour) : Number(hour) + 12;
    
            dateInit.setHours(hour24);
            dateInit.setMinutes(minutes24);

            return this.milisecondsToFormat_HHMMSS(dateNow - dateInit);
        },
        milisecondsToFormat_HHMMSS(miliseconds) {
            let seconds = miliseconds / 1000;
            const hours = parseInt(seconds / 3600);
            seconds = seconds % 3600;
            const minutes = parseInt(seconds / 60);
            seconds = parseInt(seconds % 60);

            let hoursString = hours < 10 ? "0" + String(hours) : String(hours);
            let minutesString = minutes < 10 ? "0" + String(minutes) : String(minutes);
            let secondsString = seconds < 10 ? "0" + String(seconds) : String(seconds);

            let format = hoursString + ":" + minutesString + ":" + secondsString;

            return format;
        } ,  
        base64ArrayBuffer(arrayBuffer) {
            let base64 = '';
            const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

            const bytes = new Uint8Array(arrayBuffer);
            const byteLength = bytes.byteLength;
            const byteRemainder = byteLength % 3;
            const mainLength = byteLength - byteRemainder;

            let a, b, c, d;
            let chunk;

            // Main loop deals with bytes in chunks of 3
            for (let i = 0; i < mainLength; i = i + 3) {
                chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

                a = (chunk & 16515072) >> 18;
                b = (chunk & 258048) >> 12;
                c = (chunk & 4032) >> 6;
                d = chunk & 63;

                base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
            }

            // Deal with the remaining bytes and padding
            if (byteRemainder === 1) {
                chunk = bytes[mainLength];

                a = (chunk & 252) >> 2; // 252 = 11111100
                b = (chunk & 3) << 4;   // 3   = 00000011

                base64 += encodings[a] + encodings[b] + '==';
            } else if (byteRemainder === 2) {
                chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

                a = (chunk & 64512) >> 10; // 64512 = 1111110000000000
                b = (chunk & 1008) >> 4;   // 1008  = 0000111110000000
                c = (chunk & 15) << 2;     // 15    = 0000000000001111

                base64 += encodings[a] + encodings[b] + encodings[c] + '=';
            }

            return base64;
        },
        getUrlQualtrics() {
            let dataQualtrics = this.obtainQualtrixData();
            let parametersToSend = '?Canal=Web&Servicio=ClaroBot';

            if (dataQualtrics.Usuario != null && dataQualtrics.Usuario != '') parametersToSend = parametersToSend + '&Usuario=' + dataQualtrics.Usuario;
            if (dataQualtrics.Fecha != null && dataQualtrics.Fecha != '') parametersToSend = parametersToSend + '&Fecha=' + dataQualtrics.Fecha;
            if (dataQualtrics.Hora_inicio != null && dataQualtrics.Hora_inicio != '') parametersToSend = parametersToSend + '&Hora_inicio=' + dataQualtrics.Hora_inicio;
            if (dataQualtrics.Hora_fin != null && dataQualtrics.Hora_fin != '') parametersToSend = parametersToSend + '&Hora_fin=' + dataQualtrics.Hora_fin;
            if (dataQualtrics.Motivo_visita1 != null && dataQualtrics.Motivo_visita1 != '') parametersToSend = parametersToSend + '&Motivo_visita1=' + dataQualtrics.Motivo_visita1;
            if (dataQualtrics.Motivo_visita2 != null && dataQualtrics.Motivo_visita2 != '') parametersToSend = parametersToSend + '&Motivo_visita2=' + dataQualtrics.Motivo_visita2;
            if (dataQualtrics.ID_Conversacion != null && dataQualtrics.ID_Conversacion != '') parametersToSend = parametersToSend + '&ID_Conversacion=' + dataQualtrics.ID_Conversacion;
            if (dataQualtrics.Tipo_Cliente != null && dataQualtrics.Tipo_Cliente != '') parametersToSend = parametersToSend + '&Tipo_Cliente=' + dataQualtrics.Tipo_Cliente;

            return String(localStorage.getItem('surveyLink')) + parametersToSend.replaceAll(" ", "%20");
        },
        getUrlQualtrics64() {
            let dataQualtrics = this.obtainQualtrixData();
            let json = '{';
            let value = '';

            value = '';
            if (dataQualtrics.Usuario != null && dataQualtrics.Usuario != '') {
                value = dataQualtrics.Usuario;
            }
            json = json + '"Usuario":"' + value + '",'
            json = json + '"Canal":"Web",';
            json = json + '"Servicio":"ClaroBot",';

            value = '';
            if (dataQualtrics.Fecha != null && dataQualtrics.Fecha != '') {
                value = dataQualtrics.Fecha;
            }
            json = json + '"Fecha":"' + value + '",';

            value = '';
            if (dataQualtrics.Hora_inicio != null && dataQualtrics.Hora_inicio != '') {
                value = dataQualtrics.Hora_inicio;
            }
            json = json + '"Hora_inicio":"' + value + '",';

            value = '';
            if (dataQualtrics.Hora_fin != null && dataQualtrics.Hora_fin != '') {
                value = dataQualtrics.Hora_fin;
            }
            json = json + '"Hora_fin":"' + value + '",';

            value = '';
            if (dataQualtrics.Motivo_visita1 != null && dataQualtrics.Motivo_visita1 != '') {
                value = dataQualtrics.Motivo_visita1;
            }
            json = json + '"Motivo_visita1":"' + value + '",';
            
            value = '';
            if (dataQualtrics.Motivo_visita2 != null && dataQualtrics.Motivo_visita2 != '') {
                value = dataQualtrics.Motivo_visita2;
            }
            json = json + '"Motivo_visita2":"' + value + '",';           

            value = '';
            if (dataQualtrics.ID_Conversacion != null && dataQualtrics.ID_Conversacion != '') {
                value = dataQualtrics.ID_Conversacion;
            }
            json = json + '"ID_Conversacion":"' + value + '",';  

            value = '';
            if (dataQualtrics.Tipo_Cliente != null && dataQualtrics.Tipo_Cliente != '') {
                value = dataQualtrics.Tipo_Cliente;
            }
            json = json + '"Tipo_Cliente":"' + value + '"}';

            const encoder = new TextEncoder();
            const data = encoder.encode(json);
            const _base64 = this.base64ArrayBuffer(data);

            return String(localStorage.getItem('surveyLink')) + '?Q_EED=' + _base64;
        },        
        getUrlQualtricsPR() {
            let dataPR = this.obtainQualtrixDataPR();
            let parametersToSend = '?CANAL_INGRESO=WEB&NOMBRE_SERVICIO=ClaroBot';

            if (dataPR.region != null && dataPR.region != '') parametersToSend = parametersToSend + '&REGION=' + dataPR.region;
            if (dataPR.nombre_usuario != null && dataPR.nombre_usuario != '') parametersToSend = parametersToSend + '&NOMBRE_USUARIO=' + dataPR.nombre_usuario;
            if (dataPR.fecha_atencion != null && dataPR.fecha_atencion != '' ) parametersToSend = parametersToSend + '&FECHA_ATENCION=' + dataPR.fecha_atencion ;
            if (dataPR.hora_atencion != null && dataPR.hora_atencion != '') parametersToSend = parametersToSend + '&HORA_ATENCION=' + dataPR.hora_atencion;
            if (dataPR.telefono != null && dataPR.telefono != '') parametersToSend = parametersToSend + '&TELEFONO=' + dataPR.telefono;
            if (dataPR.motivo_visita != null && dataPR.motivo_visita != '') parametersToSend = parametersToSend + '&MOTIVO_VISITA=' + dataPR.motivo_visita;
            if (dataPR.id_conversacion != null && dataPR.id_conversacion != '') parametersToSend = parametersToSend + '&ID_CONVERSACION=' + dataPR.id_conversacion;
            if (dataPR.tipo_cliente != null && dataPR.tipo_cliente != '') parametersToSend = parametersToSend + '&TIPO_CLIENTE=' + dataPR.tipo_cliente;
            if (dataPR.tiempo_total_atencion != null && dataPR.tiempo_total_atencion != '') parametersToSend = parametersToSend + '&TIEMPO_TOTAL_ATENCION=' + dataPR.tiempo_total_atencion;
            if (dataPR.email != null && dataPR.email != '') parametersToSend = parametersToSend + '&EMAIL=' + dataPR.email;

            return String(localStorage.getItem('surveyLink')) + parametersToSend.replaceAll(" ", "%20");
        },
        getUrlQualtricsPR64() {
            let dataPR = this.obtainQualtrixDataPR();
            let json = '{';
            let value = '';
            
            value = '';
            if (dataPR.region != null && dataPR.region != '') {
                value = dataPR.region;
            }
            json = json + '"REGION":"' + value + '",';

            value = '';
            if (dataPR.nombre_usuario != null && dataPR.nombre_usuario != '') {
                value = dataPR.nombre_usuario;
            }
            json = json + '"NOMBRE_USUARIO":"' + value + '",';
            json = json + '"CANAL_CANAL":"WEB",';
            json = json + '"NOMBRE_SERVICIO":"ClaroBot",';

            value = '';
            if (dataPR.fecha_atencion != null && dataPR.fecha_atencion != '') {
                value = dataPR.fecha_atencion
            }
            json = json + '"FECHA_ATENCION":"' + value + '",';

            value = '';
            if (dataPR.hora_atencion != null && dataPR.hora_atencion != '') {
                value = dataPR.hora_atencion
            }
            json = json + '"HORA_ATENCION":"' + value + '",';


            value = '';
            if (dataPR.telefono != null && dataPR.telefono != '') {
                value = dataPR.telefono;
            }
            json = json + '"TELEFONO":"' + value + '",';   

            value = '';
            if (dataPR.motivo_visita != null && dataPR.motivo_visita != '') {
                value = dataPR.motivo_visita;
            }
            json = json + '"MOTIVO_VISITA":"' + value + '",';   

            value = '';
            if (dataPR.id_conversacion != null && dataPR.id_conversacion != '') {
                value = dataPR.id_conversacion;
            }
            json = json + '"ID_CONVERSACION":"' + value + '",'; 

            value = '';
            if (dataPR.tipo_cliente != null && dataPR.tipo_cliente != '') {
                value = dataPR.tipo_cliente;
            }
            json = json + '"TIPO_CLIENTE":"' + value + '",';

            value = '';
            if (dataPR.tiempo_total_atencion != null && dataPR.tiempo_total_atencion != '') {
                value = dataPR.tiempo_total_atencion;
            }
            json = json + '"TIEMPO_TOTAL_ATENCION":"' + value + '",';

            value = '';
            if (dataPR.email != null && dataPR.email != '') {
                value = dataPR.email;
            }
            json = json + '"EMAIL":"' + value + '"}';

            const encoder = new TextEncoder();
            const data = encoder.encode(json);
            const _base64 = this.base64ArrayBuffer(data);

            return String(localStorage.getItem('surveyLink')) + '?Q_EED=' + _base64;
        }
    }
})
</script>
<style></style>
